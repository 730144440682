import { template as template_ef6069c30c734b149334608cf20f2e70 } from "@ember/template-compiler";
const SidebarSectionMessage = template_ef6069c30c734b149334608cf20f2e70(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
